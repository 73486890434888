import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import catImage from "../images/gray-kitten.jpg";

const NotFoundPage: React.FC = () => (
    <Layout>
        <SEO title="404: Not found" />

        <section className="section">
            <div className="container content">
                <h1 className="title is-1">Page Not Found</h1>
                <p>
                    Sorry, we've recently moved some things around, and the page
                    you requested was not found.
                </p>
                <p>
                    <Link to="/">Click here</Link> to visit the home page, or{" "}
                    <Link to="/contact/">contact us</Link> for assistance.
                </p>
                <img src={catImage} style={{ borderRadius: "4px" }} alt="Cat" />
            </div>
        </section>
    </Layout>
);

export default NotFoundPage;
